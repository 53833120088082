/* @font-face {
  font-family: 'MarkOT';
  src: url('../public/fonts/MarkOT.ttf');
  font-weight: 100 400;
}

@font-face {
  font-family: 'MarkOT';
  src: url('../public/fonts/MarkOT-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'MarkOT';
  src: url('../public/fonts/MarkOT-Bold.otf');
  font-weight: 600 700;
}

* {
  font-family: 'MarkOT', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: lighter;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-header {
	line-height: 16.1px !important;
}

.ant-progress-bg {
  transition-duration: 0.05s !important;
}

.paper {
  box-shadow: rgba(7, 6, 35, 0.063) 4px 4px 4px 0px;
  background: white;
  padding: 10px;
  border-radius: 10px;
}
